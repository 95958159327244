import { useEffect } from 'react'

// reusable clickoutside hook meant for modals
// will call the argument handler (modal closing function) on outside click or "ESC" key
export const useClickOutsideModal = (ref, handler, preventMouse = false) => {
	useEffect(
		function createEventListener() {
			const modalListenerHandler = (event) => {
				if (event.type === 'mouseup' && (!ref.current || ref.current.contains(event.target) || event.target.nodeName === 'A' || event.target.nodeName === 'BUTTON')) {
					event.stopPropagation()
					return
				} else {
					handler()
				}
			}

			const keyHandler = (event) => {
				if (event.key === 'Esc' || event.key === 'Escape') {
					handler()
				}
			}

			if (!preventMouse) {
				document.addEventListener('mouseup', modalListenerHandler)
			}
			document.addEventListener('keyup', keyHandler)

			return () => {
				if (!preventMouse) {
					document.removeEventListener('mouseup', modalListenerHandler)
				}
				document.removeEventListener('keyup', keyHandler)
			}
		},
		[ref, handler, preventMouse]
	)
}
