import React, { FC } from 'react'
import styles from 'components/TooltipRebuilt/ToolbuiltRebuilt.module.scss'
import { Img } from 'components/basic/Img'
import cx from 'classnames'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'

export interface TooltipRebuiltProps {
	title?: string
	body: string[]

	badgeText?: string
	promo?: {
		text: string
		img?: {
			src: string
			alt: string
		}
	}
	img?: {
		src: string
		alt: string
	}
	buttonClassName?: string
	contentClassName?: string

	trigger?: string
}

export const TooltipRebuilt: FC<TooltipRebuiltProps> = (props) => {
	return (
		<Tippy
			content={<TippyTooltipContent {...props} />}
			theme={'light'}
			duration={500}
			arrow={true}
			animation={'shift-away'}
			trigger={'mouseenter focus click'}
		>
			<button
				className={cx(styles.tooltipButton, props.buttonClassName)}
				type={'button'}
			/>
		</Tippy>
	)
}

const TippyTooltipContent: FC<TooltipRebuiltProps> = (props) => {
	console.log(props)
	const bodyText = props.body.map((paragraph, index) => (
		<p
			key={index}
			className={index === props.body.length - 1 ? 'no-mb bodytext' : 'bodytext'}
		>
			{paragraph}
		</p>
	))

	const promo = props.promo ? (
		<p className={`small ${styles.promoText}`}>
			{props.promo.img ? (
				<img
					src={props.promo.img.src}
					alt={props.promo.img.alt}
				/>
			) : null}
			{props.promo.text}
		</p>
	) : null

	return (
		<>
			<div className={cx(styles.tooltipContentMain, props.contentClassName)}>
				{props.badgeText && <p className={`badge_v1 ${styles.tooltipBadge}`}>{props.badgeText}</p>}
				{promo}
				{props.title ? <h4 className={'no-mb title'}>{props.title}</h4> : null}
				{bodyText}
				{props.img ? (
					<Img
						alt={props.img.alt}
						src={props.img.src}
						lazy={false}
					/>
				) : null}
			</div>
		</>
	)
}
